<template>
  <div
    class="form-builder__text"
    :class="schema.styleClasses || ''"
  >
    <div v-if="schema.label" :class="schema.labelStyleClasses">
      {{ schema.label }}
    </div>

    <div v-if="schema.placeholder" :class="schema.placeholderStyleClasses">
      {{ schema.placeholder }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
